<template>
  <v-container>
    <v-breadcrumbs
      class="py-2"
      :items="[
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'Dashboard' },
        },
        {
          text: `Liste des bennes`,
          disabled: false,
          exact: true,
          to: { name: 'Containers' }
        },
        {
          text: `Cycles de bennes`,
          disabled: true
        },
      ]"
    />
    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <v-card v-else class="mt-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="8" md="9">
            <div class="d-flex align-center">
              <v-icon size="4em" class="mr-4">mdi-trash-can</v-icon>
              <span
                class="text-h4 font-weight-regular"
                v-if="container.code"
              >
                {{ container.code }}
              </span>
            </div>
            <div class="mt-3">
              <div v-if="container.defaultWasteType.id" class="mb-2">
                Type de déchet associé :
                <router-link
                  :to="{ name: 'ViewWasteType', params: { id: container.defaultWasteType.id } }">
                  {{ container.defaultWasteType.name }}
                </router-link>
              </div>
              <div class="mb-2" v-if="container.currentCycle && container.currentCycle.fillPercentage != null">
                Rempli théoriquement à {{ container.currentCycle.fillPercentage }} %
                ({{ container.currentCycle.mass }} /{{ container.currentCycle.wasteType.maximumContainerMass }} kg)
              </div>
              <div v-else>
                Pas encore de remplissage théorique
              </div>
              {{ container.description }}
            </div>
            <!-- <v-slider
            v-model="fillPercentage"
            min="0"
            max="100"
            /> -->
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-btn block color="info" text @click="$refs.editForm.open(container)">
              <v-icon left>mdi-pencil</v-icon> Modifier
            </v-btn>
            <v-btn block class="mt-2" color="error" text @click="$refs.removeForm.open(container)">
              <v-icon left>mdi-delete</v-icon> Supprimer
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-progress-linear
        :value="fillPercentage"
        :height="10"
        :color="progressBarColor"
      />
    </v-card>
    <v-card class="mt-3">
      <!-- <v-tabs
        v-model="activeTab"
        exact
        centered
        fixed-tabs
      >
        <v-tab :to="{ name: 'SiteElevators' }" exact>
          Ascenseurs
        </v-tab>
        <v-tab :to="{ name: 'SiteContainerCycles' }" exact>
          Cycles de container
        </v-tab>
      </v-tabs> -->
      <router-view
        :container="container"
        @cycleUpdate="fetchData"
      ></router-view>
    </v-card>
    <EditContainer
      ref="editForm"
      @finished="fetchData"
    />
    <RemoveItem
      resource="containers"
      title="cette benne"
      ref="removeForm"
      @finished="$router.push({ name: 'Containers' })"
    />
  </v-container>
</template>

<script>
import EditContainer from "@/components/Containers/EditContainer.vue"
import RemoveItem from "@/components/RemoveItem.vue"

export default {
  components: {
    EditContainer,
    RemoveItem
  },
  data: () => ({
    activeTab: '',
    fillMaximum: 0,
    mass: 0,
    container: {
    },
    loading: true
  }),
  mounted () {
    this.fetchData()
    this.refreshTab()
    if (this.$route.name == 'ViewContainer') {
      this.$router.push({ name: 'ContainerCycles', params: { id: this.$route.params.id } })
    }
  },
  computed: {
    fillPercentage () {
      if (!this.container.currentCycle) {
        return 0
      }
      if (this.container.currentCycle.fillPercentage == null) {
        return 0
      }
      return this.container.currentCycle.fillPercentage
    },
    progressBarColor () {
      let value = this.fillPercentage
      /**
      Vert : 0 à 35%
      Jaune : 36 à 60%
      Orange : 61 à 85%
      Rouge : 86 à 100%
       */
      if (value >= 0 && value <= 35) {
        return 'green'
      }
      if (value > 35 && value <= 60) {
        return 'yellow'
      }
      if (value > 60 && value <= 85) {
        return 'amber'
      }
      if (value > 86) {
        return 'red'
      }
      return 'error'
    }
  },
  watch: {
    '$route.path'() {
      this.refreshTab()
    }
  },
  methods: {
    refreshTab() {
      this.activeTab = this.$route.path
    },
    fetchData() {
      this.loading = true
      this.$store.dispatch('containers/fetchOne', this.$route.params.id).then(res => {
        this.loading = false
        this.container = res

        // if (!res.currentCycle) {
        //   this.$store.commit('alert/add', {
        //     type: 'warning',
        //     message: 'Impossible de calculer le remplissage de la benne. Aucun cycle actuellement en cours.'
        //   })
        //   return
        // }

        // this.mass = res.currentCycle.mass
        // this.fillMaximum = res.currentCycle.wasteType.maximumContainerMass
        // if (!this.fillMaximum || this.fillMaximum < 0) {
        //   this.$store.commit('alert/add', {
        //     type: 'warning',
        //     message: "Impossible de calculer le remplissage de la benne. Le type de déchet associé n'a pas de poids maximum."
        //   })
        //   return
        // }

        //this.fillPercentage = Math.round((this.mass / this.fillMaximum) * 100)
      }).catch(err => {
        console.error(err)
        this.loading = false
        this.$store.commit('alert/add', {
          type: 'error',
          message: 'Impossible de charger la benne'
        })
      })
    }
  }
}
</script>
